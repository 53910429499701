import {
    REQUEST_TAX_SUMMARY,
    RECEIVE_TAX_SUMMARY,
    ERROR_TAX_SUMMARY
} from '../types'
import {fetchUrl} from '../../constants/constants'
import {requestItem, receiveItem, failedItem, getEnums, reloadDashboard} from "../actions"
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'
import prepareFilters from "../../helpers/prepareFilters"

function request(date) {
    return {
        type: REQUEST_TAX_SUMMARY,
        date
    };
}

export function receive(data, date) {
    return {
        type: RECEIVE_TAX_SUMMARY,
        payload: data,
        date
    }
}

function failed(data, date) {
    return {
        type: ERROR_TAX_SUMMARY,
        payload: data,
        date
    }
}

export function getTaxSummary(params = {}, force = false) {
    return (dispatch, getState) => {
        const date = params.date || 0
        const summary = getState().taxSummary
        if (shouldFetch(summary[date] || summary, !force)) {
            dispatch(request(date))
            return axios({
                method: 'GET',
                url: fetchUrl + 'tax-summary',
                params: prepareFilters(params, getState(), "taxSummary")
            }).then(res => {
                dispatch(receive(res.data.data, date))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors), date)
            })
        } else {
            return Promise.resolve()
        }
    }
}
