import {
    REQUEST_ORDERS,
    RECEIVE_ORDERS,
    UPDATE_ORDERS,
    ERROR_ORDERS
} from '../types'
import {fetchUrl} from '../../constants/constants'
import {requestItem, receiveItem, failedItem, updateItem, reloadDashboard} from "../actions"
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'
import prepareFilters from "../../helpers/prepareFilters";

function request() {
    return {
        type: REQUEST_ORDERS
    };
}

export function receive(data) {
    return {
        type: RECEIVE_ORDERS,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_ORDERS,
        errors: data
    }
}

function update(data, id, updateType) {
    return {
        type: UPDATE_ORDERS,
        id,
        payload: data,
        updateType
    }
}

export function getOrders(params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().orders, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'orders',
                params: prepareFilters(params, getState(), "orders")
            }).then(res => {
                dispatch(receive(res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function getOrder(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('order'))
            return axios({
                method: 'GET',
                url: fetchUrl + 'orders/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function createOrder(data) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'POST',
                url: fetchUrl + 'orders',
                data
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_created'))
                dispatch(update(res.data.data, false, 'create'))
                dispatch(reloadDashboard())
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function updateOrder(data, id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('order'))
            return axios({
                method: 'PUT',
                url: fetchUrl + 'orders/' + id,
                data
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                dispatch(reloadDashboard())
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function patchOrder(data, id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('order'))
            return axios({
                method: 'PATCH',
                url: fetchUrl + 'orders/' + id,
                data
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function updateProphylaxis(data, id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('order'))
            return axios({
                method: 'PUT',
                url: fetchUrl + 'orders/prophylaxes/' + id,
                data
            }).then(res => {
                //dispatch(updateItem(res.data.data, 'prophylaxes'))
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function sendProphylaxis(data, id) {
    return (dispatch, getState) => {
        return axios({
            method: 'POST',
            url: fetchUrl + 'orders/prophylaxes-email/' + id,
            data
        }).then(res => {
            dispatch(updateItem({...res.data.data, id}, 'prophylaxesSentLog'))
            return true
        }).catch(err => {
            return false
        })
    }
}

export function deleteOrder(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'DELETE',
                url: fetchUrl + 'orders/' + id
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_deleted'))
                dispatch(update(res.data.data, id, 'delete'))
                dispatch(reloadDashboard())
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}