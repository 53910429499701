import {
    REQUEST_TAX_SUMMARY,
    RECEIVE_TAX_SUMMARY,
    ERROR_TAX_SUMMARY,
} from '../types'
import {
    initialFetchState, initialTaxSummaryFetchState,
} from '../../constants/defaultValues'
import {listRefresh} from "../../constants/constants"
import _get from "lodash/get"

export default (state = initialTaxSummaryFetchState, action = {}) => {
    let newState = {...state}
    switch(action.type) {
        case REQUEST_TAX_SUMMARY:
            newState[action.date] = {
                ...initialFetchState,
                data: _get(newState, [action.date, 'data'], {}),
                isFetching: true,
            }
            return newState
        case RECEIVE_TAX_SUMMARY:
            newState[action.date] = {
                ...newState[action.date],
                isFetching: false,
                data: action.payload,
                shouldReload: false,
                validUntil: listRefresh.default + Date.now(),
            }
            return newState
        case ERROR_TAX_SUMMARY:
            newState[action.date] = {
                ...initialFetchState,
                isFetching: true,
                errors: action.errors,
                message: action.errors.server || '',
            }
            return newState
        default:
            return state
    }
}