import {
    REQUEST_KIOSKS_MAP,
    RECEIVE_KIOSKS_MAP,
    ERROR_KIOSKS_MAP
} from '../types'
import {fetchUrl} from '../../constants/constants'
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'

function request() {
    return {
        type: REQUEST_KIOSKS_MAP
    };
}

export function receive(data) {
    return {
        type: RECEIVE_KIOSKS_MAP,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_KIOSKS_MAP,
        errors: data
    }
}

export function getKiosksMap(data = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().kiosksMap, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'orders/map',
                data
            }).then(res => {
                dispatch(receive(res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}
